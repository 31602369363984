<template>
    <div class="flex justify-center w-full serve_page">
        <div class="w-full serve_box">
            <div>
                <!-- <img src="../../../../assets/images/serve/banner.png" alt /> -->
                <img :src="getLogo.advertising" alt style="width: 100vw;" />
            </div>
            <div class="my-4 font-bold serve_title">自助服务</div>
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data() {
        return {

        };
    },
    computed: {
        ...mapGetters(['getLogo'])
    },
    components: {

    },
    methods: {

    },
};
</script>

<style lang='scss' scoped>
.serve_page {
    background: var(--bg-gray);
    min-height: var(--min-height);
}
.box {
    width: 100%;
    max-width: var(--max-width);
    padding: 0 var(--margin-base);
}
@media screen and (max-width: 640px) {
    .serve_title {
        font-size: 1rem;
    }
}
@media screen and (min-width: 640px) {
    .serve_title {
        font-size: 1.125rem;
    }
}
</style>